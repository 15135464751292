import React from 'react';
import classNames from 'classnames';

interface MaterialIconProps {
    className?: string;
    icon: string;
}

/**
 * MaterialIcon
 */
const MaterialIcon = (props: MaterialIconProps) => {
    return <i className={classNames("mdi", props.className, ("mdi-" + props.icon))} />;
};

export default MaterialIcon;
