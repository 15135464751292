import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { setLoadingState } from "../../../../redux/actions";

import PageTitle from "components/PageTitle";
import { Row, Col, Card, Button } from "react-bootstrap";
import FancySelect from "components/FancySelect";
import { get, getRelatorioCoordenadores } from "helpers";
import { GetSelectColaboradores, IColaboradorDA } from "types";

interface ISelect {
  label: string;
  value: number;
}

const RelatoriosCoordenadores: React.FC = () => {
  const [colaboradores, setColaboradores] = useState<ISelect[]>([]);
  const [colaboradorSelecionado, setColaboradorSelecionado] = useState<
    undefined | ISelect
  >();

  const searchSelectColaboradores = async () => {
      dispatch(setLoadingState(true));
      try {
        const { data } = await get<GetSelectColaboradores>(
          "/select-colaborador",
          { pageSize: 1000,  somente_ativo: true, somente_coordenadores: true }
        );
        setColaboradores(
          data.result.map((colaborador: IColaboradorDA) => ({
            label: colaborador.usuario.nome,
            value: colaborador.id,
          }))
        );
      } catch (error) {
        console.log({ error });
      }
      dispatch(setLoadingState(false));
    }

  useEffect(() => {
    searchSelectColaboradores();
  }, []);

  const dispatch = useDispatch<AppDispatch>();

  const baixarRelatorio = async () => {
    dispatch(setLoadingState(true));
    try {
      let queryString = ''

      if (colaboradorSelecionado?.value !== null) {
        queryString += `?coordenador_id=${colaboradorSelecionado?.value}`;
      }

      const response = await getRelatorioCoordenadores(queryString);

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const url = window.URL.createObjectURL(blob);

      const element = document.createElement("a");

      const reportGenerationDay = Intl.DateTimeFormat("pt-br", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      })
        .format(new Date())
        .replaceAll("/", "-");

      const filename = `relatorio-coordenadores-${colaboradorSelecionado?.label}-${reportGenerationDay}.xlsx`;

      element.href = url;
      element.download = filename;

      element.style.display = "none";
      document.body.append(element);
      element.click();

      element.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log({ error });
    }
    dispatch(setLoadingState(false));
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          {
            label: "Configurações",
            path: "/relatorios-coordenadores",
          },
          {
            label: "Relatórios Coordenadores",
            path: "/relatorios-coordenadores",
            active: true,
          },
        ]}
        title={"Relatórios Coordenadores"}
      />

      <Row>
        <Card>
          <Card.Body>
            <h4 className="mb-3">Filtros</h4>
            <Row className="justify-content-between">
              <Col lg={4}>
              <FancySelect
                value={colaboradorSelecionado}
                label="Colaborador"
                name="colaborador"
                placeholder="Selecione"
                options={colaboradores}
                onChange={(e) => setColaboradorSelecionado(e)}
              />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>

      <Row className="d-flex justify-content-end">
        <Button
          type="submit"
          className="btn btn-primary waves-effect waves-light mb-2"
          style={{ width: "200px" }}
          onClick={baixarRelatorio}
        >
          Gerar relatório
        </Button>
      </Row>
    </>
  );
};

export default RelatoriosCoordenadores;
