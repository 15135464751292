import React from "react";
import Select from "react-select";
import "../assets/scss/transfer.scss";

interface IFancySelect {
  options: { value: number; label: string }[];
  name: string;
  label: string;
  placeholder: string;
  value?:
    | { value: number; label: string }
    | { value: number; label: string }[]
    | null;
  isSearchable?: boolean;
  components?: any;
  errors?: any;
  isRequired?: boolean;
  onMenuScrollToBottom?: () => void;
  onInputChange?: (e: string) => void;
  onChange?: (e: any | null) => void;
  isMulti?: boolean;
  className?: string;
  disabled?: boolean;
}

const FancySelect: React.FC<IFancySelect> = ({
  options,
  name,
  label,
  placeholder,
  value,
  errors,
  components,
  isSearchable,
  isRequired,
  isMulti,
  onMenuScrollToBottom,
  onInputChange,
  onChange,
  className,
  disabled = false,
}) => {
  return (
    <div
      className={
        className ? `position-relative ${className}` : "position-relative"
      }
    >
      <label className="form-label">
        {label} {isRequired ? <span style={{ color: "#f00" }}>*</span> : null}
      </label>
      <Select
        styles={{
          control: (provided, state) => ({
            ...provided,
            borderColor: errors && errors[name] ? "#F2667A" : "#ced4da",
          }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            color: errors && errors[name] ? "#F2667A" : "#ced4da",
          }),
        }}
        value={value}
        className={errors && errors[name] ? "is-invalid" : ""}
        placeholder={placeholder}
        isSearchable={isSearchable}
        options={options}
        components={components}
        onMenuScrollToBottom={onMenuScrollToBottom}
        onInputChange={onInputChange}
        onChange={onChange}
        isMulti={isMulti}
        isDisabled={disabled}
      />

      {errors && errors[name] ? (
        <div className="invalid-feedback">{errors[name]["message"]}</div>
      ) : null}
    </div>
  );
};

export default FancySelect;
