import { APICore } from "./apiCore";

const api = new APICore();

const getRelatorioCoordenadores = (queryString?: string) => {
  return api.get(`/relatorio-coordenadores${queryString ? queryString : ""}`, {
    responseType: "blob",
  });
};

export { getRelatorioCoordenadores };
